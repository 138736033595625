import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import lightGallery from "lightgallery";
import { MetaTags } from 'react-meta-tags';
const Gallery = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <div>
       <MetaTags>
    <title>Unisex Gym in Goregaon | Iron Gym</title>
    <meta title=" Unisex Gym in Goregaon | Iron Gym" />
    <meta name="description" content="Join the ultimate Unisex Gym in Goregaon for a transformative fitness experience. Achieve your goals with our expert trainers and state-of-the-art facilities! 💪" />
    <meta name="keywords" content="Best Gym in Goregaon,Top Unisex Gym Goregaon,Best Fitness Centers in Goregaon ,Unisex Gym in Goregaon,Gym near Goregaon" />
    <link rel="canonical" href="https://ironparadiesgym.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Unisex Gym in Goregaon | Iron Gym" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://ironparadiesgym.in/unisex-gym-goregaon" />
    <meta property="og:description" content="Join the ultimate Unisex Gym in Goregaon for a transformative fitness experience. Achieve your goals with our expert trainers and state-of-the-art facilities! 💪" />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
            <section
  className="breadcrumb-area d-flex  p-relative align-items-center"
  style={{ backgroundImage: "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/bdrc-bg.jpg)" }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-left">
          <div className="breadcrumb-title">
            <h1>Unisex Gym in Goregaon</h1>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Our Gallery
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div style={{paddingTop:"20px"}}>
<div className="container">
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              href="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img01.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img01.jpg"
            >
              <img
                src="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img01.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img02.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img02.jpg"
            >
              <img
                src="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img02.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 1"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img03.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img03.jpg"
            >
              <img
                src="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img03.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img04.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img04.jpg"
            >
              <img
                src="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img04.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img05.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img05.jpg"
            >
              <img
                src="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img05.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img06.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img06.jpg"
            >
              <img
                src="https://htmldemo.zcubethemes.com/maxfit/img/gallery/protfolio-img06.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
          </div>
        </div>
</div>
<div>
  <p>
  Welcome to Iron Paradise, the premier unisex gym in Goregaon, where fitness meets inclusivity. Our state-of-the-art facility is meticulously designed to cater to the diverse fitness needs of everyone, regardless of gender. At Iron Paradise, we take pride in fostering a welcoming and inclusive environment, making us the leading unisex gym in Goregaon.
  </p>
  <p>
  Our gallery showcases an impressive array of top-notch equipment, from cutting-edge cardio machines to advanced strength training tools. Whether you're a beginner or a seasoned athlete, our diverse workout areas are equipped to support all fitness levels. We understand that a comfortable and supportive atmosphere is essential for a productive workout, which is why we prioritise creating a space where everyone feels at ease.
  </p>
  <p>
  Iron Paradise offers a variety of group classes that cater to different fitness goals and preferences. From high-intensity interval training (HIIT) to calming yoga sessions, our classes are designed to challenge and inspire you.
  </p>
  <p>
  What sets Iron Paradise apart as the best unisex gym in Goregaon is our vibrant and supportive community. Our members are encouraged to motivate and uplift each other, fostering a sense of camaraderie that enhances the overall gym experience. We believe that fitness is more than just physical exercise; it's about building relationships, boosting confidence, and achieving personal growth.
 
  </p>
  <p>
  Joining Iron Paradise is the first step towards a healthier, more active lifestyle. We invite you to experience the unique blend of professionalism, inclusivity, and community that defines our gym. As the foremost unisex gym in Goregaon, we are committed to helping you achieve your fitness goals in a space designed for everyone. Discover the Iron Paradise difference and embark on your fitness journey with us today.
  </p>
</div>
    </div>
  )
}

export default Gallery
