import React,{useState} from 'react'

import "../style/Hadder.css"
import LOGO from "../asset/LOGO.png"
const Hadder = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <header className="header-area header desktopview">
  <div id="header-sticky" className="menu-area">
    <div className="container">
      <div className="second-menu">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-3">
            <div className="logo">
              <a href="/">
                <img src={LOGO} alt="logo" />
              </a>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="main-menu text-right text-xl-right">
              <nav id="mobile-menu" style={{ display: "block" }}>
                <ul>
                  <li className="has-sub">
                    <a href="/">Home</a>
                  
                  </li>
                  <li>
                    <a href="/top-unisex-gym-goregaon">About Us</a>
                  </li>
                  <li className="has-sub">
                    <a href="/best-fitness-centers-goregaon">Classes</a>
                   
                  </li>
                  
                  <li className="has-sub">
                    <a href="/unisex-gym-goregaon">Gallery</a>
                   
                  </li>
                  <li>
                    <a href="/gym-near-goregaon">Contact Us</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
            <div className="login">
              <ul>
                <li>
                  <a href="#" className="menu-tigger">
                    <i className="fal fa-search" />
                  </a>
                </li>
                <li>
                  <div className="second-header-btn">
                    <a href="/gym-near-goregaon" className="btn">
                      Join Us Now
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="mobile-menu" />
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<header className="header-area header laptopview">
  <div id="header-sticky" className="menu-area sticky-menu">
    <div className="container">
      <div className="second-menu">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-3">
            <div className="logo">
              <a href="index.html">
                <img src={LOGO} alt="logo" />
              </a>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="main-menu text-right text-xl-right">
              <div className="mean-push" />
              <nav id="mobile-menu" style={{ display: "none" }}>
                <ul>
                  <li className="has-sub">
                    <a href="index.html">Home</a>
                    <ul>
                      <li>
                        <a href="index.html">Home Page 01</a>
                      </li>
                      <li>
                        <a href="index-2.html">Home Page 02</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="about.html">About Us</a>
                  </li>
                  <li className="has-sub">
                    <a href="classes.html">Classes</a>
                    <ul>
                      <li>
                        <a href="classes.html">Classes</a>
                      </li>
                      <li>
                        <a href="single-classes.html">Classes Details</a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <a href="#">Pages</a>
                    <ul>
                      <li>
                        <a href="projects.html">Gallery</a>
                      </li>
                      <li>
                        <a href="pricing.html">Pricing</a>
                      </li>
                      <li>
                        <a href="team.html">Team</a>
                      </li>
                      <li>
                        <a href="faq.html">Faq</a>
                      </li>
                      <li>
                        <a href="shop.html">Shop</a>
                      </li>
                      <li>
                        <a href="shop-details.html">Shop Details</a>
                      </li>
                      <li>
                        <a href="404-error.html">404 Error</a>
                      </li>
                      <li>
                        <a href="thank-you.html">Thank You</a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <a href="#">Blog</a>
                    <ul>
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/gym-near-goregaon">Contact Us</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
            <div className="login">
              <ul>
                <li>
                  <a href="#" className="menu-tigger">
                    <i className="fal fa-search" />
                  </a>
                </li>
                <li>
                  <div className="second-header-btn">
                    <a href="/gym-near-goregaon" className="btn">
                      Join Us Now
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="mobile-menu mean-container">
              <div className="mean-bar">
                <a
                  onClick={toggleMenu}
                  className="meanmenu-reveal"
                  style={{ background: "", color: "", right: 0, left: "auto" }}
                >
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </a>
                <nav className="mean-nav">
                  <ul style={{ display: isMenuOpen ? "block" : "none" }}>
                
                  <li className="has-sub">
                    <a href="/">Home</a>
                  
                  </li>
                  <li>
                    <a href="/top-unisex-gym-goregaon">About Us</a>
                  </li>
                  <li className="has-sub">
                    <a href="/Service">Classes</a>
                   
                  </li>
                  
                  <li className="has-sub">
                    <a href="/Gallery">Gallery</a>
                   
                  </li>
                  <li>
                    <a href="/gym-near-goregaon">Contact Us</a>
                  </li>
               
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>


  
    </div>
  )
}

export default Hadder
