import React from "react";
import abimg from "../asset/ABIMG.png";
import { MetaTags } from 'react-meta-tags';
const Aboutus = () => {
  return (
    <div>
       <MetaTags>
    <title>Top Unisex Gym Goregaon | Iron Gym</title>
    <meta title=" Top Unisex Gym Goregaon | Iron Gym" />
    <meta name="description" content="Discover the ultimate fitness destination at the Best Gym in Goregaon. Achieve your goals with expert guidance. Visit us today!" />
    <meta name="keywords" content="Best Gym in Goregaon,Top Unisex Gym Goregaon,Best Fitness Centers in Goregaon ,Unisex Gym in Goregaon,Gym near Goregaon" />
    <link rel="canonical" href="https://ironparadiesgym.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Top Unisex Gym Goregaon | Iron Gym" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://ironparadiesgym.in/top-unisex-gym-goregaon" />
    <meta property="og:description" content="Discover the ultimate fitness destination at the Best Gym in Goregaon. Achieve your goals with expert guidance. Visit us today!" />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
      <section
        className="breadcrumb-area d-flex  p-relative align-items-center"
        style={{
          backgroundImage:
            "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/bdrc-bg.jpg )",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  <h1>Top Unisex Gym Goregaon</h1>
                  <div className="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          About Us
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          {/* <img src="" alt="contact-bg-an-01" /> */}
          <div />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft  animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInLeft" }}
              >
                <img src={abimg} alt="about us" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-60 wow fadeInRight   animated"
                data-animation="fadeInRight"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                <div className="about-title second-title pb-25">
                  <h5>About Us</h5>
                  <h2>Get A Best Shape Of Your Body</h2>
                </div><p>
                Welcome to Top Unisex in Gym Goregaon – Your Path to Success
                </p>
                <p>
                At Top Unisex Gym in Goregaon, we believe that hard work is the foundation of success. Every step forward you take here drives your growth, and every lift strengthens your resolve. Join our motivated community at Top Unisex Gym Goregaon, where inspiration and support are in abundance, and let us help you achieve your fitness goals.
                Our gym is equipped with state-of-the-art facilities designed to cater to all your fitness needs. Whether you're aiming to build muscle, increase endurance, or simply stay healthy, we have everything you need. Our extensive range of weights and resistance machines will challenge and build your strength, while our advanced cardio equipment will help you push your limits and enhance your stamina.

                </p>
                <div className="about-content2 mt-50">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>
                                <img
                                  src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ab-icon-01.png"
                                  alt="img"
                                />{" "}
                              </span>
                            </div>{" "}
                            <div className="text">
                              <h3>1684</h3> <p>Trained People</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>
                                <img
                                  src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ab-icon-02.png"
                                  alt="img"
                                />{" "}
                              </span>
                            </div>{" "}
                            <div className="text">
                              <h3>1876</h3> <p>Modern Equipment</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="counter-area p-relative pt-50 pb-20"
        style={{
          backgroundImage:
            "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-bg.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row p-relative">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">874</span>
                      </div>
                      <div className="text">
                        <p>Our Happy Clients</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">789</span>
                      </div>
                      <div className="text">
                        <p>Our Best Trainers</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">985</span>
                      </div>
                      <div className="text">
                        <p>Cup Of Coffee For Me</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">698</span>
                      </div>
                      <div className="text">
                        <p>Our Latest Equipment</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <section
        className="offer-area fix p-relative pt-120 pb-120"
        style={{
          backgroundImage:
            "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/offer-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-relative">
              <div
                className="section-title center-align mb-50 wow fadeInDown  animated"
                data-animation="fadeInDown"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h5>Offer</h5>
                <h2>
                  Get The Best <br />
                  Traning Guide
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  {/* <img
                    src="https://htmldemo.zcubethemes.com/maxfit/img/bg/offer-tage.png"
                    alt="an-img-01"
                  /> */}
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="text">
                    <ul className="green">
                      <li>Sweat, Lift, Repeat, Achieve.</li>
                      <li>
                      Stronger with every lift, stronger with every step.
                      </li>
                      <li>
                      Push through the challenge, embrace the grind, conquer the goals.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-relative" />
          </div>
        </div>
      </section>
      <div className="container">
        <p>
        We understand that everyone's fitness journey is unique, and our dedicated team of professional trainers is here to guide you every step of the way. They offer personalised workout plans and one-on-one coaching to ensure you reach your specific goals Our trainers are enthusiastic about seeing you succeed in addition to being authorities in their fields. They provide the motivation and encouragement you need to stay committed and make steady progress.
        </p>
        <p>
        At Top Unisex Gym in Goregaon, we foster a community atmosphere where members support and inspire each other. Our group fitness classes are a great way to stay motivated and make new friends. From high-intensity interval training to yoga, we offer a variety of classes to keep your workouts exciting and effective.
        </p>
        <p>
        Redefine what’s possible with us. Fortify your body and mind, and shape your future one rep at a time. When you step up and work hard, greatness is within your reach. Join us today and become a part of a community that believes in pushing boundaries and achieving excellence together. Top Unisex Gym Goregaon – Together, We Achieve Greatness.

        </p>
      </div>
      <section className="cta-area cta-bg pt-60 pb-60">
        <div className="cta-background">
          <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/cta-bg.png"
            alt="about image"
          />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div
                className="section-title cta-title wow fadeInLeft  animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ visibility: "visible", animationName: "fadeInLeft" }}
              >
                <h2>We Are Provide Best Fitness Service For You</h2>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <div
                className="cta-btn s-cta-btn wow fadeInRight  animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                <a href="tel:+919769279603" className="btn ss-btn smoth-scroll">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
    </div>
  );
};

export default Aboutus;
