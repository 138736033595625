import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FaRegCheckCircle } from "react-icons/fa";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../style/responsive.css";
import strength from "../asset/classstringh.avif"
import "../style/menu.css";
// import required modules
import { Pagination } from "swiper/modules";
import "../style/default.css";
import Slider from "./Slider";
import abimg from "../asset/ABIMG.png";
import bannerimage1 from "../asset/iage.avif"
// import slider-img from "../asset/slider-img.png"
import counterbg from "../asset/counter-bg.png"
import servicebg from "../asset/services-bg.png"
import seticon from "../asset/ser-icon-01.png"
import ctabg from "../asset/cta-bg.png"
import banner from "../asset/compressimag/banner.avif"
import aboutbaner from "../asset/compressimag/aboutus.avif"
import t1 from "../asset/compressimag/t1.avif"
import t2 from "../asset/compressimag/t2.avif"
import t3 from "../asset/compressimag/t3.avif"
import t4 from "../asset/compressimag/t4.avif"
import strimg from "../asset/streimg.avif"
<ass></ass>
const Home = () => {
  return (
    <div>
      <Slider />
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/ani-img-01.png"
            alt="best gym in Goregaon"
          />
          <div />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft  animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInLeft" }}
              >
                <img src={aboutbaner} alt="about gym" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-60 wow fadeInRight   animated"
                data-animation="fadeInRight"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                <div className="about-title second-title pb-25">
                  <h5>About Us</h5>
                  <h1>Welcome to the Best Gym in Goregaon</h1>
                </div>
                <p>
                At our gym, dedication is not just a word; it's a way of life. Here, every lift you make builds your resolve and each stride you take drives your advancement. We believe in transforming effort into success, one workout at a time.
                Our community at the Best Gym in Goregaon is composed of motivated individuals who support and encourage each other. From the moment you walk in, you’ll feel the positive energy and camaraderie that define our gym. We understand that everyone has unique fitness goals, whether it’s enhancing cardio endurance, building strength, or achieving a balanced, healthy lifestyle. Our mission is to support you in reaching those objectives, no matter how big or small.
                </p>
                <div className="about-content2 mt-50">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>
                                <img
                                  src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ab-icon-01.png"
                                  alt="trained people"
                                />{" "}
                              </span>
                            </div>{" "}
                            <div className="text">
                              <h3>1000</h3> <p>Trained People</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>
                                <img
                                  src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ab-icon-02.png"
                                  alt="modern equipment"
                                />{" "}
                              </span>
                            </div>{" "}
                            <div className="text">
                              <h3>100</h3> <p>Modern Equipment</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="services-05"
        className="services-05 pt-120 pb-150 p-relative fix"
        style={{
          background:
            `url(${servicebg})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="animations-01">
          {/* <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/ani-img-02.png"
            alt="contact-bg-an-01"
          /> */}
          <div />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center center-align">
            <div className="col-lg-6 col-md-12">
              <div
                className="section-title text-center mb-20 wow fadeInDown   animated"
                data-animation="fadeInDown"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h5>Our Classes</h5>
                <h2>Classes We Provide</h2>
              </div>
            </div>
          </div>
          <div className="rows services-active align-items-center justify-content-center slick-initialized slick-slider slick-dotted">
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={{
                  opacity: 1,

                  // transform: "translate3d(-1296px, 0px, 0px)"
                }}
              >
                <Swiper
                  // slidesPerView={3}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      // spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      // spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      // spaceBetween: 50,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://cdn.shopify.com/s/files/1/1876/4703/articles/shutterstock_360151730_1600x.jpg?v=1620231292"
                              alt="gym in Goregaon"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "330px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="body building"
                            />
                          </div>
                          <h5>Body Building</h5>
                          <h4>
                            {" "}
                            <a href="single-classes.html" tabIndex={-1}>
                              Bodybuilding Contest Preparation
                            </a>{" "}
                          </h4>
                          <p>
                            Preparing for a bodybuilding contest involves
                            rigorous training....
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://img.freepik.com/free-photo/young-bodybuilder-running-cardio-workout-looking-gym-window_496169-2718.jpg"
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "330px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Cardio</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Cardio
                            </a>{" "}
                          </h4>
                          <p>
                            Boost endurance, strengthen heart: prioritize
                            regular cardio workouts for health
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* -------------------------------- */}
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id="first"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://www.mensjournal.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTk2MTM3Mjk2NTQ5NTIwNTI5/_main_liftlift.jpg"
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "330px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Weight training
                            </a>{" "}
                          </h4>
                          <p>
                            Build strength and muscle through consistent weight
                            training workouts.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://qph.cf2.quoracdn.net/main-qimg-69ee2c5f33585a27547f671f7d9a4d87-lq"
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "330px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Functional Training
                            </a>{" "}
                          </h4>
                          <p>
                            Functional training enhances daily movements for
                            optimal physical performance and wellness.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://images.onlymyhealth.com/imported/images/2022/May/26_May_2022/main%20(31).jpg"
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "330px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Weight Management
                            </a>{" "}
                          </h4>
                          <p>
                            Balanced diet and exercise foster successful weight
                            management strategies.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src={strength}
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "290px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Strength Training
                            </a>{" "}
                          </h4>
                          <p>
                            Build muscle, boost strength through consistent,
                            focused strength training workouts.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://www.constantcontact.com/blog/wp-content/uploads/2021/01/Social-16.jpg"
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "347px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Personal Training
                            </a>{" "}
                          </h4>
                          <p>
                            Enhance fitness goals with tailored personal
                            training sessions and guidance.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src="https://www.dietitiannupur.com/wp-content/uploads/2019/10/Top-10-Dietitians-in-Hyderabad.jpg"
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div
                          className="services-content-05"
                          style={{ height: "270px" }}
                        >
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Internationally Certified Dietician for
                              Consultation
                            </a>{" "}
                          </h4>
                          <p className="expert">
                            Expert diet advice available from internationally
                            certified dietitian for consultations.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className=" slick-slide slick-cloned servicesection"
                      data-slick-index={-3}
                      id=""
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <div
                        className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="services-icon-05">
                          <a href="single-classes.html" tabIndex={-1}>
                            <img
                              src={strimg}
                              alt="icon01"
                            />
                          </a>
                        </div>
                        <div className="services-content-05">
                          <div className="icon">
                            <img
                              src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                              alt="icon01"
                            />
                          </div>
                          <h5>Gym Fit</h5>
                          <h4>
                            {" "}
                            <a
                              href="single-classes.html"
                              tabIndex={-1}
                              style={{ fontSize: "23px" }}
                            >
                              Prep Consulting
                            </a>{" "}
                          </h4>
                          <p>
                            Prep Consulting: Your Partner for Strategic Business
                            Solutions and Growth.
                          </p>
                          <div className="blog-btn">
                            <a href="single-classes.html" tabIndex={-1}>
                              {/* Read More{" "} */}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="counter-area p-relative pt-50 pb-20"
        style={{
          backgroundImage:
            `url(${counterbg})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row p-relative">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">874</span>
                      </div>
                      <div className="text">
                        <p>Our Happy Clients</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">789</span>
                      </div>
                      <div className="text">
                        <p>Our Best Trainers</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">985</span>
                      </div>
                      <div className="text">
                        <p>Cup Of Coffee For Me</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="single-counter mb-30"
                    style={{
                      backgroundImage:
                        "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/counter-icon.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      className="counter p-relative wow fadeInDown  animated"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <span className="count">698</span>
                      </div>
                      <div className="text">
                        <p>Our Latest Equipment</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="team-area2 fix p-relative pt-120 pb-120">
        <div className="animations-01">
          <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/ani-img-05.png"
            alt="an-img-01"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-relative">
              {/* <div
                className="section-title center-align mb-50 text-center wow fadeInDown  animated"
                data-animation="fadeInDown"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h5>TEAM STAFF</h5>
              
              </div> */}
            </div>
          </div>
          {/* <div className="row team-active slick-initialized slick-slider slick-dotted">
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={{
                  opacity: 1,
                }}
              >
                <div className="row">
                  <Swiper
                    // slidesPerView={3}
                    spaceBetween={10}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div
                        className="slick-slide slick-cloned traner"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div className="single-team mb-40">
                          <div className="team-thumb">
                            <div className="brd">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/team/team01.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="team-info">
                            <p>Personal Trainer</p>
                            <h4>
                              <a href="team-single.html" tabIndex={-1}>
                                Zinia Zessy
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="slick-slide slick-cloned traner"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div className="single-team mb-40">
                          <div className="team-thumb">
                            <div className="brd">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/team/team02.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="team-info">
                            <p>Personal Trainer</p>
                            <h4>
                              <a href="team-single.html" tabIndex={-1}>
                                Zinia Zessy
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="slick-slide slick-cloned traner"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div className="single-team mb-40">
                          <div className="team-thumb">
                            <div className="brd">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/team/team03.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="team-info">
                            <p>Personal Trainer</p>
                            <h4>
                              <a href="team-single.html" tabIndex={-1}>
                                Zinia Zessy
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section
        className="offer-area fix p-relative pt-120 pb-120"
        style={{
          backgroundImage:
            `url(${bannerimage1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-relative">
              <div
                className="section-title center-align mb-50 wow fadeInDown  animated"
                data-animation="fadeInDown"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h5>Offer</h5>
                <h2>
                  Get The Best <br />
                  Traning Guide
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  {/* <img
                    src="https://htmldemo.zcubethemes.com/maxfit/img/bg/offer-tage.png"
                    alt="an-img-01"
                  /> */}
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="text">
                    <ul className="green">
                      <li>Sweat, Lift, Repeat, Achieve.</li>
                      <li>
                      Stronger with every lift, stronger with every step.
                      </li>
                      <li>
                      Push through the challenge, embrace the grind, conquer the goals.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-relative" />
          </div>
        </div>
      </section>
      <section
        id="pricing"
        className="pricing-area pt-120 pb-60 fix p-relative"
      >
        <div className="animations-01">
          <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/ani-img-02.png"
            alt="an-img-01"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb-35">
                <h5>PRICING CHART</h5>
                <h2>Choose Pricing Package</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="pricing-box pricing-box2 mb-60  wow fadeInUp   animated"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="pricing-head">
                  <h5>BASIC</h5>
                  <div className="icon">
                    <img
                      src="https://htmldemo.zcubethemes.com/maxfit/img/icon/pirce-icon-03.png"
                      alt="basic gym"
                    />
                  </div>
                </div>
                <div className="pricing-body mt-20 mb-30 text-left">
                  <ul>
                    <div className="row">
                      <div className="col-6">
                        <li>12 months - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 15000</h5>
                        </div>
                      </div>
                      <div className="col-6">
                        <li>6 months - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 12000</h5>
                        </div>
                      </div>
                      <div className="col-6">
                        <li>3 months - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 9000</h5>
                        </div>
                      </div>
                      <div className="col-6">
                        <li>1 month - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 4000</h5>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>

                <div className="pricing-btn mt-10">
                  <a href="/gym-near-goregaon" className="btn ss-btn smoth-scroll">
                    <span>Enquire now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="pricing-box pricing-box2 mb-60  wow fadeInUp   animated"
                style={{
                  visibility: "visible",
                  animationName: "fadeInUp",
                  minHeight: "88%",
                }}
              >
                <div className="pricing-head">
                  <h5>Diet Consultation </h5>
                  <div className="icon">
                    <img
                      src="https://htmldemo.zcubethemes.com/maxfit/img/icon/pirce-icon-03.png"
                      alt="Diet Consultation"
                    />
                  </div>
                </div>
                <div className="pricing-body mt-20 mb-30 text-left">
                  <ul>
                    <div className="row">
                      <div className="col-6">
                        <li>1 month - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 2000</h5>
                        </div>
                      </div>
                      <div className="col-6">
                        <li>3 months - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 5000</h5>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>

                <div className="pricing-btn mt-10">
                  <a href="/gym-near-goregaon" className="btn ss-btn smoth-scroll">
                    <span>Enquire now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="pricing-box pricing-box2 mb-60  wow fadeInUp   animated"
                style={{
                  visibility: "visible",
                  animationName: "fadeInUp",
                  minHeight: "88%",
                }}
              >
                <div className="pricing-head">
                  <h5>Student Offer</h5>
                  <div className="icon">
                    <img
                      src="https://htmldemo.zcubethemes.com/maxfit/img/icon/pirce-icon-03.png"
                      alt="Student Offer"
                    />
                  </div>
                </div>
                <div className="pricing-body mt-20 mb-30 text-left">
                  <ul>
                    <div className="row">
                      <div className="col-6">
                        <li>12 month - </li>
                      </div>
                      <div className="col-6">
                        {" "}
                        <div className="price-count">
                          {" "}
                          <h5> ₹ 12500</h5>
                        </div>
                      </div>
                    </div>
                    <li>with one month Diet Consultation free</li>
                  </ul>
                </div>

                <div className="pricing-btn mt-10">
                  <a href="/gym-near-goregaon" className="btn ss-btn smoth-scroll">
                    <span>Enquire now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="testimonial-area pt-120 pb-100 p-relative fix"
        style={{
          background:
            "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/services-bg.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="animations-02">
          <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/ani-img-05.png"
            alt="animation"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title mb-50 wow fadeInDown  text-center animated"
                data-animation="fadeInDown"
                data-delay=".4s"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h5>Our Testimonial</h5>
                <h2>What Our Clients Says</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="testimonial-active slick-initialized slick-slider slick-dotted">
                <div className="slick-list draggable">
                  <div
                    className="slick-track"
                    style={{
                      opacity: 1,
                    }}
                  >
                    <div className="row">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        breakpoints={{
                          634: {
                            slidesPerView: 1,
                            // spaceBetween: 20,
                          },
                          768: {
                            slidesPerView: 3,
                            // spaceBetween: 40,
                          },
                          1024: {
                            slidesPerView: 3,
                            // spaceBetween: 50,
                          },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <div
                            className="single-testimonial slick-slide slick-cloned"
                            data-slick-index={-2}
                            aria-hidden="true"
                            tabIndex={-1}
                            // style={{ height: "290px" }}
                          >
                            <p className="pt-10 pb-20">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/review-icon.png"
                                alt="testimonial"
                              />
                            </p>
                            <p>
                              Really good facility,good equipments and great
                              environment. Good service with friendly staff...
                            </p>

                            <div className="testi-author">
                              <img
                                src={t1}
                                alt="testimonial"
                                style={{ width: "50px", borderRadius: "36px" }}
                              />
                              <div className="ta-info">
                                <h6>Rahul Kumar</h6>
                                <span>Client</span>
                              </div>
                            </div>
                            <div className="qt-img">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/qt-icon.png"
                                alt="testimonial"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className="single-testimonial slick-slide slick-cloned"
                            data-slick-index={-1}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            // style={{ height: "290px" }}
                          >
                            <p className="pt-10 pb-20">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/review-icon.png"
                                alt="gym in goregaon"
                              />
                            </p>
                            <p>
                              Best gym in Goregaon .. we’ll set up best
                              equipment .. friendly trainers . Worth of money.
                            </p>
                            <div className="testi-author">
                              <img
                                src={t2}
                                alt="testimonial"
                                style={{ width: "50px", borderRadius: "36px" }}
                              />
                              <div className="ta-info">
                                <h6>Nadeem Shaikh</h6>
                                <span>Client</span>
                              </div>
                            </div>
                            <div className="qt-img">
                              <img src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/qt-icon.png" alt="testimonial"  />
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className="single-testimonial slick-slide slick-cloned"
                            data-slick-index={-1}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                          >
                            <p className="pt-10 pb-20">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/review-icon.png"
                                alt="testimonial"
                              />
                            </p>
                            <p>
                              Ambience, environment is very fit minded. With
                              plenty of knowledgeable trainers. Overall very
                              good 😊. ….
                            </p>
                            <div className="testi-author">
                              <img
                                src={t3}
                                alt="img"
                                style={{ width: "50px", borderRadius: "36px" }}
                              />
                              <div className="ta-info">
                                <h6>Nikhil Kasar</h6>
                                <span>Client</span>
                              </div>
                            </div>
                            <div className="qt-img">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/qt-icon.png"
                                alt="img"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className="single-testimonial slick-slide slick-cloned"
                            data-slick-index={-1}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                          >
                            <p className="pt-10 pb-20">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/review-icon.png"
                                alt="testimonial"
                              />
                            </p>
                            <p>
                              he gym has excellent equipments and always has a
                              good atmosphere to work out. Equipmets in the
                              gym...
                            </p>
                            <div className="testi-author">
                              <img
                                src={t4}
                                alt="testimonial"
                                style={{ width: "50px", borderRadius: "36px" }}
                              />
                              <div className="ta-info">
                                <h6>Vedant Shinde</h6>
                                <span>Client</span>
                              </div>
                            </div>
                            <div className="qt-img">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/testimonial/qt-icon.png"
                                alt="testimonial"
                              />
                            </div>
                          </div>
                        </SwiperSlide>

                        {/* ------------------------------------------------------------------ */}

                   
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <p>
        For those focusing on cardio, we offer a range of equipment and classes designed to boost your heart health and stamina. From state-of-the-art treadmills and elliptical machines to high-energy group classes like Zumba and spinning, we have everything you need to keep your heart pumping and your energy levels high. Our knowledgeable trainers at the Best Gym in Goregaon are always available to provide guidance and ensure you’re getting the most out of your cardio sessions.
        </p>
        <p>
        If strength training is your priority, you’ll find a comprehensive selection of free weights, machines, and functional training equipment. Our strength coaches are experts in developing personalized programs that target your specific needs, whether you’re aiming to build muscle mass, increase power, or improve overall fitness. They’ll work with you to perfect your form, track your progress, and push you beyond your limits safely and effectively.
        </p>
        <p>
        We believe that together, we can sculpt, strengthen, and break boundaries. Every member of our gym is part of our fitness family, and we’re dedicated to fostering an environment where everyone feels empowered and inspired.
        </p>
        <p>
        So, step in and give it your all. Let’s reach greatness together, one rep at a time. Join us at the Best Gym in Goregaon, and transform your dedication into undeniable success. Your fitness journey starts here.
        </p>

      </div>
      <section className="cta-area cta-bg pt-60 pb-60">
        <div className="cta-background">
          <img
            src={ctabg}
            alt="footer"
          />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div
                className="section-title cta-title wow fadeInLeft  animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ visibility: "visible", animationName: "fadeInLeft" }}
              >
                <h2>We Are Provide Best Fitness Service For You</h2>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <div
                className="cta-btn s-cta-btn wow fadeInRight  animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                <a href="tel:9867550868" className="btn ss-btn smoth-scroll">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      
    </div>
  );
};

export default Home;
