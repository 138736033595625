import React from "react";
import LOGO from "../asset/LOGO.png";
import footerimg from "../asset/footer-bg.png"
import { IoCall } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <footer
        className="footer-bg footer-p pt-120"
        style={{
          backgroundColor: "#fff",
          backgroundImage:
            `url(${footerimg})`,
          backgroundPosition: "0 0",
        }}
      >
        <div className="footer-top pb-70">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-30">
                    <a href="/">
                      <img src={LOGO} alt="img" />
                    </a>
                  </div>
                  <div className="f-contact">
                    <p>
                      At Iron Paradise Gym, we transcend mediocrity, forging
                      ironclad determination. We push boundaries, redefine
                      power.
                    </p>
                  </div>
                  <div className="footer-social mt-10">
                    <a href="https://www.facebook.com/ironparadisegym1">
                      <FaFacebook />
                    </a>
                    <a href="https://www.instagram.com/ironparadiseegym/">
                      <FaInstagram />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Our Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/top-unisex-gym-goregaon">About Us</a>
                      </li>
                      <li>
                        <a href="/best-fitness-centers-goregaon">Classes</a>
                      </li>
                      <li>
                        <a href="/unisex-gym-goregaon">Gallery</a>
                      </li>
                      <li>
                        <a href="/gym-near-goregaon">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal">
                          <IoCall />
                        </i>

                        <span>
                          <a href="tel:+91-9769279603">+91-9769279603</a>
                          <br />
                          <a href="tel:+91-9004660096">+91-9004660096</a>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal">
                          <IoMail />
                        </i>

                        <span>
                          <a href="mailto:info@example.com">
                            iron.paradisegym1@gmail.com
                          </a>
                          <br />
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="icon fal">
                            <MdLocationOn />
                          </i>
                        </span>
                        <span>
                          B-43, Sidha Co Operative Housing Society, 169,
                          Siddharth Nagar Rd, Siddhartha Nagar, part B, Goregaon
                          West, Mumbai, Maharashtra 400104
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Location</h2>
                  </div>
                  <div className="f-insta">
                    <div style={{ borderRadius: 25 }}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.931306663104!2d72.84138407497949!3d19.154483982066353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7d906900409%3A0xc0338deae93e86c2!2sIron%20Paradise%20Gym%20Goregaon%20west!5e0!3m2!1sen!2sin!4v1711564719947!5m2!1sen!2sin"
                        style={{ borderRadius: 25 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: "400" }}>
                  Copyright © Iron Paradise Gym 2024 .{" "}
                </p>
                <p style={{ fontWeight: "400" }}>
                  Developed by Shri Krishna Digital.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
