
import './App.css';
import Footer from './components/Footer';
import Hadder from './components/Hadder';
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import Services from './pages/Services';
import Slider from './pages/Slider';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/style/responsive.css"
import "../src/style/menu.css"
import "../src/style/Hadder.css"
import "../src/style/default.css"
import "../src/style/slick.css"
import Error from './components/Error';
function App() {
  return (
    <>
<BrowserRouter> 
      <Hadder/>
      
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/top-unisex-gym-goregaon' element={<Aboutus/>}></Route>
     <Route path='/gym-near-goregaon' element={<Contectus/>}></Route>
     <Route path='/best-fitness-centers-goregaon' element={<Services/>}></Route>
     <Route path='/unisex-gym-goregaon' element={<Gallery/>}></Route>
  

     <Route path="/*" element={<Error/>}></Route>
     
     {/* {S
            Servicedata.map((route, index) => {
              console.log("route.slug",route.slug)
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Servicedetil/>}
                  />
                </>
              )
            })
          } */}
      </Routes>
<Footer/>
    </BrowserRouter> 
    </>
  );
}

export default App;
