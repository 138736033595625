import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../style/responsive.css";
import { Pagination } from "swiper/modules";
import "../style/menu.css";
import { MetaTags } from 'react-meta-tags';
const Services = () => {
  return (
    <div>
       <MetaTags>
    <title>Best Fitness Centers in Goregaon | Iron Gym</title>
    <meta title=" Best Fitness Centers in Goregaon | Iron Gym" />
    <meta name="description" content=" Discover the Best Fitness Centres in Goregaon, offering state-of-the-art facilities and expert guidance. Start your fitness journey today!" />
    <meta name="keywords" content="Best Gym in Goregaon,Top Unisex Gym Goregaon,Best Fitness Centers in Goregaon ,Unisex Gym in Goregaon,Gym near Goregaon" />
    <link rel="canonical" href="https://ironparadiesgym.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Best Fitness Centers in Goregaon | Iron Gym" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://ironparadiesgym.in/best-fitness-centers-goregaon" />
    <meta property="og:description" content=" Discover the Best Fitness Centres in Goregaon, offering state-of-the-art facilities and expert guidance. Start your fitness journey today!" />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
      <section
        className="breadcrumb-area d-flex  p-relative align-items-center"
        style={{
          backgroundImage:
            "url(https://htmldemo.zcubethemes.com/maxfit/img/bg/bdrc-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  <h1>Best Fitness Centers in Goregaon </h1>
                  <div className="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Our Classes
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-05"
        className="services-05 pt-120 pb-150 p-relative fix"
        style={{
          background: "url(img/bg/services-bg.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="animations-01">
          <div />
        </div>
        <div className="container">
          <div className="rows services-active align-items-center justify-content-center slick-initialized slick-slider slick-dotted">
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={{
                  opacity: 1,

                  // transform: "translate3d(-1296px, 0px, 0px)"
                }}
              >
                <div className="row">
                  <Swiper
                    // slidesPerView={3}
                    spaceBetween={10}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://img.freepik.com/free-photo/young-bodybuilder-running-cardio-workout-looking-gym-window_496169-2718.jpg"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Cardio</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Cardio
                              </a>{" "}
                            </h4>
                            <p>
                              Boost endurance, strengthen heart: prioritize
                              regular cardio workouts for health
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://www.mensjournal.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTk2MTM3Mjk2NTQ5NTIwNTI5/_main_liftlift.jpg"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Gym Fit</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Weight training
                              </a>{" "}
                            </h4>
                            <p>
                              Build strength and muscle through consistent
                              weight training workouts.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://qph.cf2.quoracdn.net/main-qimg-69ee2c5f33585a27547f671f7d9a4d87-lq"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Gym Fit</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Functional Training
                              </a>{" "}
                            </h4>
                            <p>
                              Functional training enhances daily movements for
                              optimal physical performance and wellness.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://images.onlymyhealth.com/imported/images/2022/May/26_May_2022/main%20(31).jpg"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Gym Fit</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Weight Managemen
                              </a>{" "}
                            </h4>
                            <p>
                              Balanced diet and exercise foster successful
                              weight management strategies.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-4}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://assets.sweat.com/shopify_articles/images/010/005/385/original/BenefitsOfStrengthTrainingForWomencdc853cb74a233646df40500d0cf977b.jpg?1628137530"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Gym Fit</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Strength Training
                              </a>{" "}
                            </h4>
                            <p>
                              Build muscle, boost strength through consistent,
                              focused strength training workouts.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://www.constantcontact.com/blog/wp-content/uploads/2021/01/Social-16.jpg"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Gym Fit</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Personal Training
                              </a>{" "}
                            </h4>
                            <p>
                              Enhance fitness goals with tailored personal
                              training sessions and guidance.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://www.dietitiannupur.com/wp-content/uploads/2019/10/Top-10-Dietitians-in-Hyderabad.jpg"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Gym Fit</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Internationally Certified Dietician for
                                Consultation
                              </a>{" "}
                            </h4>
                            <p>
                              Expert diet advice available from internationally
                              certified dietitian for consultations.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div
                        className=" slick-slide slick-cloned servicesection"
                        data-slick-index={-3}
                        id=""
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <div
                          className="services-box-05 mb-30 hover-zoomin wow fadeInUp   animated"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="services-icon-05">
                            <a href="single-classes.html" tabIndex={-1}>
                              <img
                                src="https://www.dietitiannupur.com/wp-content/uploads/2019/10/Top-10-Dietitians-in-Hyderabad.jpg"
                                alt="icon01"
                              />
                            </a>
                          </div>
                          <div className="services-content-05">
                            <div className="icon">
                              <img
                                src="https://htmldemo.zcubethemes.com/maxfit/img/icon/ser-icon-01.png"
                                alt="icon01"
                              />
                            </div>
                            <h5>Bodybuilding Contest Preparation</h5>
                            <h4>
                              {" "}
                              <a href="single-classes.html" tabIndex={-1}>
                                Internationally Certified Dietician for
                                Consultation
                              </a>{" "}
                            </h4>
                            <p>
                              Expert diet advice available from internationally
                              certified dietitian for consultations.
                            </p>
                            <div className="blog-btn">
                              <a href="single-classes.html" tabIndex={-1}>
                                {/* Read More{" "} */}
                                <i className="fal fa-long-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <p>
        Iron Paradise stands out as one of the best fitness centres in Goregaon, offering top-notch facilities and expert trainers dedicated to helping you achieve your fitness goals. Located in the heart of Goregaon, this premier fitness centre provides a comprehensive range of state-of-the-art equipment and a diverse selection of fitness classes, catering to all fitness enthusiasts.
        </p>
        <p>
        At Iron Paradise, you'll find an array of options to suit your workout preferences, whether you're passionate about strength training, cardio, or enjoy the energy of group workouts. The gym is equipped with the latest machines and tools designed to provide an effective and efficient workout experience for members at all fitness levels. From advanced weightlifting gear to high-tech cardio machines, Iron Paradise ensures you have everything you need to enhance your fitness routine. As one of the best fitness centres in Goregaon, it guarantees a superior fitness experience.
        </p>
        <p>
        What sets Iron Paradise apart is its team of professional and knowledgeable trainers who are committed to providing personalised training programs tailored to meet your individual fitness objectives. Whether you're a beginner looking to start your fitness journey or an experienced athlete aiming to push your limits, the trainers at Iron Paradise offer expert guidance and support to help you reach your goals. This dedication further solidifies its reputation as one of the best fitness centres in Goregaon.
        </p>
        <p>
        The gym's motivating environment fosters a sense of community and encourages members to challenge themselves and stay committed to their fitness journey. The variety of fitness classes, including yoga, HIIT, Zumba, and more, ensures there's something for everyone, making workouts both fun and effective.
        </p>
        <p>
        Iron Paradise is more than just a gym; it's a destination where you can transform your fitness journey with the help of top-tier facilities and dedicated trainers. Join Iron Paradise today and experience one of the **best fitness centres in Goregaon**. Embark on a path to a healthier, fitter you in a supportive and dynamic environment designed to bring out the best in you.
        </p>
      </div>
      <section className="cta-area cta-bg pt-60 pb-60">
        <div className="cta-background">
          <img
            src="https://htmldemo.zcubethemes.com/maxfit/img/bg/cta-bg.png"
            alt="img"
          />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div
                className="section-title cta-title wow fadeInLeft  animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ visibility: "visible", animationName: "fadeInLeft" }}
              >
                <h2>We Are Provide Best Fitness Service For You</h2>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <div
                className="cta-btn s-cta-btn wow fadeInRight  animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
                style={{ visibility: "visible", animationName: "fadeInRight" }}
              >
                <a href="about.html" className="btn ss-btn smoth-scroll">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </div>
  );
};

export default Services;
