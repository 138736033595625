import React from 'react'
import { FaFacebookF } from "react-icons/fa";
import sliderbg from "../asset/slider-bg-01.png"
import sliderimag from "../asset/slider-img.avif"
import banner from "../asset/compressimag/banner.avif"
const Slider = () => {
  return (
    <div>
      <section
  id="parallax"
  className="slider-area slider-two fix p-relative"
  style={{

    transformStyle: "preserve-3d",
    backfaceVisibility: "hidden"
  }}
>
  {/* slider-info-area */}
  <div className="slider-info">
    <div className="social">
      <span>
        <a href="#" title="Facebook">
          <i className="fab fa-facebook-f" />
          <FaFacebookF />
        </a>
        <a href="#" title="LinkedIn">
          <i className="fab fa-linkedin-in" />
        </a>
        <a href="#" title="Twitter">
          <i className="fab fa-twitter" />
        </a>
        <a href="#" title="Twitter">
          <i className="fab fa-youtube" />
        </a>
      </span>
      {/*  /social media icon redux */}
    </div>
  </div>
  {/* slider-info-area-end */}
  <div className="slider-active slick-initialized slick-slider">
    <button type="button" className="slick-prev slick-arrow" style={{}}>
      <i className="far fa-angle-left" />
    </button>
    <div className="slick-list draggable">
      <div className="slick-track" style={{}}>
        <div
          className="single-slider slider-bg slider-bg2 d-flex align-items-center slick-slide"
          style={{
            background:
              `url(${banner}) 0% 0% / cover no-repeat`,
            width:"100%",
            position: "relative",
            left: 0,
            top: 0,
            zIndex: 998,
           
            transition: "opacity 500ms ease 0s"
          }}
          data-slick-index={0}
          aria-hidden="true"
          tabIndex={-1}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="slider-content s-slider-content pt-80">
                  <h5
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className=""
                    style={{ animationDelay: "0.4s" }}
                  >
                    Welcome To Iron Paradise Gym
                  </h5>
                  <h2
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className=""
                    style={{ animationDelay: "0.4s" }}
                  >
                    Get Your Goal With Fitness
                  </h2>
                  <p
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className=""
                    style={{ animationDelay: "0.4s" }}
                  >
                   Forge strength, sculpt muscle, and ignite determination at Iron Paradise: where iron meets willpower.
                  </p>
                  <div className="slider-btn mt-30">
                    <a
                      href="about.html"
                      className="btn mr-15"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                      tabIndex={-1}
                      style={{ animationDelay: "0.4s" }}
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 p-relative ">
                <div
                  className="banner-img"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                  style={{ animationDelay: "0.4s" }}
                >
                  <img src={sliderimag} alt="banner" />
                </div>
                <div className="s-aliment-1 ">
                  {/* <img
                    // src="img/slider/bpm.png"
                    alt="icon"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className=""
                    style={{ animationDelay: "0.4s" }}
                  /> */}
                </div>
                <div className="s-aliment-2 ">
                  {/* <img
                    // src="img/slider/calories.png"
                    // alt="icon"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className=""
                    style={{ animationDelay: "0.4s" }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
   
      </div>
    </div>
    <button type="button" className="slick-next slick-arrow" style={{}}>
      <i className="far fa-angle-right" />
    </button>
  </div>
</section>

    </div>
  )
}

export default Slider
